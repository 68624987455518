import * as types from '../mutation-types'
const company = {
  state: {
    ALBBToken: {},
    fileType: [],
    userInfo: {},
    companyInfo: {},
    usersUpdate: false,
    companyId: '',
    projectId: '',
    companyType: '',
    companyUsers: [] // 公司下所有账号
  },
  getters: {
    companyInfo: state => state.companyInfo,
    companyUsers: state => state.companyUsers,
    ALBBToken: state => state.ALBBToken,
    fileType: state => state.fileType,
    companyId: state => state.companyId,
    projectId: state => state.projectId,
    companyType: state => state.companyType,
    userInfo: state => state.userInfo,
    usersUpdate: state => state.usersUpdate,
  },
  mutations: {
    [types.SET_COMPANYINFO] (state, info) {
      state.companyInfo = JSON.parse(JSON.stringify(info))
    },
    [types.SET_COMPANYUSERS] (state, users) {
      state.companyUsers = users
    },
    [types.SET_COMPANYID] (state, id) {
      state.companyId = id
    },
    [types.SET_PROJECTID] (state, id) {
      state.projectId = id
    },
    [types.SET_COMPANYTYPE] (state, id) {
      state.companyType = id
    },
    [types.SET_USERSUPDATE] (state) {
      state.usersUpdate = !state.usersUpdate
    },
    [types.SET_USERINFO] (state, userInfo) {
      state.userInfo = JSON.parse(JSON.stringify(userInfo))
    },
    [types.SET_FILETYPE] (state, type) {
      state.fileType = type
    },
    [types.SET_ALBBTOKEN] (state, data) {
      state.ALBBToken = data
      if (window.SITE_CONFIG['baseUrl'].match('wechat.vvstore.cc')) {
        state.ALBBToken.dir = 'test/' + state.ALBBToken.dir
      }
    }
  },
  actions: {
  }
}

export default company
