import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import user from './modules/user'
import company from './modules/company'
import webSocket from './modules/webSocket'
import jimuUrl from './modules/jimu'
import dictionaryData from './modules/getDictionary'
import filterProperties from './modules/filterProperties'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    user,
    company,
    webSocket,
    jimuUrl,
    dictionaryData,
    filterProperties
  },
  strict: process.env.NODE_ENV !== 'production'
})
