const filterProperties = {
  state: {
    saleProjectPage: {},
    purcharseProjectPage: {},
  },
 
  mutations: {
    saveSaleProjectPageBstate(state, data) {
       state.saleProjectPage = data
    },
    savePurcharseProjectPageBstate(state, data) {
       state.purcharseProjectPage = data
    }
    
   }
}
export default filterProperties
