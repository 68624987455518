import * as types from '../mutation-types'
const state = {
  webSocket: null,
  lockReconnect: false,//是否已断开连接
  data: null,
  pingInterval: null,
  isDisconnection: false,//是否已经断开网络连接
  initialFn: null,//页面第一次发送消息的函数
}
const actions = {
  //初始化   
  initWebSocket({ state, commit, dispatch }, prot) {
    if (state.webSocket) {
      return
    }
    // let baseUrl = (process.env.NODE_ENV == 'production' ? 'wss://mine.vvstore.cc/yf' : process.env.NODE_ENV == 'test' ? 'wss://mine.vvstore.cc/yf' : 'ws://172.16.0.144:8020/yf') + `/ws/${prot}/` + localStorage.getItem('userId')
    // let baseUrl = 'wss://mine.vvstore.cc/yf' + `/ws/${prot}/` + localStorage.getItem('userId') //线上
    // let baseUrl = 'ws://172.16.0.144:8020/yf' + `/ws/${prot}/` + localStorage.getItem('userId') //本地
    let baseUrl =`${process.env.VUE_APP_WEBSOCKET_URL}/ws/${prot}/` + localStorage.getItem('userId') 
    console.log(baseUrl);
    const webSocket = new WebSocket(baseUrl)
    webSocket.onopen = () => {
      commit('SET_LINK_STATE', false)
      commit('LOCK_RE_CONNECT', false)
      dispatch('heartCheck')
    }
    webSocket.onmessage = e => {
      commit('SET_DATA', JSON.parse(e.data))
    }
    webSocket.onclose = (e) => {
      console.log('关闭',e);
      dispatch('reConnect', prot)
    }
    webSocket.onerror = (e) => {
      console.log('错误',e);
      dispatch('reConnect', prot)
    }
    commit('INIT_WEBSOCKET', webSocket)
  },
  // 心跳包
  heartCheck({ state, commit }) {
    // console.log('触发了');
    state.initialFn&&state.initialFn()
    const { webSocket } = state
    const pingInterval = setInterval(() => {
      if (webSocket?.readyState === 1) {//值为0，表示正在连接,值为1，表示连接成功，可以通信了,值为2，表示连接正在关闭,值为3，表示连接已经关闭，或者打开连接失败。
        webSocket.send('PING')
      }
    }, 30000)
    commit('SET_PING_INTERVAL', pingInterval)
  },
  //
  //重新链接
  reConnect({ state, commit, dispatch }, prot) {
    if (state.lockReconnect) {
      return
    }
    console.log('重新连接');
    commit('SET_LINK_STATE', true)
    commit('INIT_WEBSOCKET', null)
    commit('LOCK_RE_CONNECT', true)
    setTimeout(() => {
      dispatch('initWebSocket', prot)
      commit('LOCK_RE_CONNECT', false)
    }, 5000)
  },
  //发送消息
  sendMsg({ state, commit }, msg) {
    if (!state.webSocket) {
      return
    }
    // console.log(msg);
    commit('SEND_MSG', msg)
  },
  //断开连接
  closeSocket({ commit }) {
    commit('CLOSE_SOCKET')
    commit('SET_DATA', null)
    commit('LOCK_RE_CONNECT', true)
    commit('SET_SOCKET_FN', null)
  },
}
const getters = {
  webSocket: state => state.webSocket,
  socketData: state => state.data,
  isDisconnection: state => state.isDisconnection,
  initialFn : state => state.initialFn
}
const mutations = {
  [types.INIT_WEBSOCKET](state, data) {
    state.webSocket = data
  },
  [types.LOCK_RE_CONNECT](state, data) {
    state.lockReconnect = data
  },
  [types.SET_PING_INTERVAL](state, data) {
    state.pingInterval = data
  },
  [types.SET_DATA](state, data) {
    state.data = data
  },
  [types.SEND_MSG](state, data) {
    state.webSocket.send(data)
  },
  [types.CLOSE_SOCKET](state) {
    if (state.webSocket) {
      state.webSocket.close()
    }
    state.webSocket = null
  },
  [types.SET_LINK_STATE](state, data) {
    state.isDisconnection = data
  },
  [types.SET_SOCKET_FN](state,data){
    state.initialFn = data
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
